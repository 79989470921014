
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { useI18n } from "vue-i18n";

interface grade {
  mark: number;
}

export default defineComponent({
  name: "Student-Attendance-Statistic",
  props: {
    title: String,
    description: String,
    data: Object,
    height: Number,
  },
  components: {},
  setup(props) {
    const { t } = useI18n();

    const grades = props.data as grade[];
    const data = [0, 0, 0, 0];

    grades.forEach((g) => {
      if (g.mark < 5) data[0]++;
      else if (g.mark < 10) data[1]++;
      else if (g.mark < 15) data[2]++;
      else data[3]++;
    });

    const primaryColor = getCSSVariableValue("--bs-primary");
    const warningColor = getCSSVariableValue("--bs-warning");
    const dangerColor = getCSSVariableValue("--bs-danger");
    const successColor = getCSSVariableValue("--bs-success");

    const colors = [dangerColor, warningColor, primaryColor, successColor];

    const series = [
      {
        name: t("notes.notes"),
        data,
      },
    ];

    const options = {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors,
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [
          t("notes.lessThan", { nb: 5 }),
          [t("notes.between"), t("notes.aAndB", { a: 5, b: 9 })],
          [t("notes.between"), t("notes.aAndB", { a: 10, b: 14 })],
          t("notes.moreThan", { nb: 14 }),
        ],
        labels: {
          style: {
            colors,
            fontSize: "13px",
          },
        },
      },
      yaxis: {
        min: 0,
        max: Math.max(...data) + 2,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
    };
    return {
      options,
      series,
    };
  },
});
