import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row my-1" }
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "col-5"
}
const _hoisted_6 = {
  key: 1,
  class: "col-5"
}
const _hoisted_7 = {
  key: 2,
  class: "col-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('notes.note')) + " :", 1),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.marks.mark === '' || _ctx.edits)
          ? (_openBlock(), _createBlock(_component_el_input, {
              key: 0,
              onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (
            _ctx.marks.mark === '' ? _ctx.addMark('one') : _ctx.updateMark(_ctx.marks._id, 'one')
          ), ["enter"])),
              class: "w-50",
              size: "small",
              modelValue: _ctx.newMarks,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newMarks) = $event))
            }, null, 8, ["modelValue"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.marks.mark) + " / 20 ", 1))
      ]),
      (_ctx.marks.mark !== '' && !_ctx.edits)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_el_button, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.edits = true)),
              disabled: _ctx.disabledAccess,
              type: "primary",
              icon: _ctx.Edit,
              size: "small"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('notes.edit')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "icon"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.marks.mark === '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_el_button, {
              icon: _ctx.Check,
              class: "mr-2",
              disabled: _ctx.disabledAccess,
              type: "success",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addMark('one'))),
              size: "small"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('notes.save')), 1)
              ]),
              _: 1
            }, 8, ["icon", "disabled"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.edits)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_el_button, {
              icon: _ctx.Check,
              class: "mr-2",
              type: "primary",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateMark(_ctx.marks._id, 'one'))),
              size: "small"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('notes.confirm')), 1)
              ]),
              _: 1
            }, 8, ["icon"]),
            _createVNode(_component_el_button, {
              icon: _ctx.Delete,
              onClick: _cache[5] || (_cache[5] = 
            () => {
              _ctx.edits = false
            }
          ),
              type: "danger",
              size: "small"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('notes.cancel')), 1)
              ]),
              _: 1
            }, 8, ["icon"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}