
import { defineComponent, ref, onMounted, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import ApiService from '@/core/services/ApiService'
import cmarks from './cmarks.vue'
import { useI18n } from 'vue-i18n'
import GradeChart from './gradeChart.vue'
import MaxminChart from './maxminChart.vue'
import JwtService from '@/core/services/JwtService'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { event } from 'vue-gtag'

interface NewNoteData {
  trimestre: string
}
interface IStudent {
  _id: string
  BirthDate: string
  firstName: string
  lastName: string
  gender: string
  schoolarYearsHistory: string[]
}

interface ISubject {
  _id: string
  name: string
  status: string
}

interface Mark {
  _id: string
  type: string
  mark: string
  schoolarYear: string
  student: string
  subject: string
  trimester: number
}

export default defineComponent({
  name: 'student-grades',
  components: {
    cmarks,
    GradeChart,
    MaxminChart,
  },
  props: {},
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const store = useStore()
    const filter = ref<string>('')
    const classID = ref<string>('')
    const maxmin = ref([])
    const student = ref<IStudent>({
      _id: '',
      BirthDate: '',
      firstName: '',
      lastName: '',
      gender: '',
      schoolarYearsHistory: [],
    })
    const listSubject = ref<ISubject[]>([])
    const apiUrl = ref<string>(store.getters.serverConfigUrl.base_url + '/')

    const sy = window.localStorage.getItem('activeSchoolarYear')
    const trimester = JwtService.getTrimester()
    const loading = ref(true)

    const targetData = ref<NewNoteData>({
      trimestre: trimester || '1',
    })

    const disabledAccess = ref(true)

    const currentMonth = new Date().getMonth()
    if (targetData.value.trimestre == '1') {
      if (currentMonth >= 8 && currentMonth <= 11) disabledAccess.value = false
    } else if (targetData.value.trimestre == '2') {
      if (currentMonth >= 12 && currentMonth <= 2) disabledAccess.value = false
    } else if (targetData.value.trimestre == '3') {
      if (currentMonth >= 2 && currentMonth <= 5) disabledAccess.value = false
    }

    const rules = ref({
      matiere: [
        {
          required: true,
          message: 'Please select Activity zone',
          trigger: 'change',
        },
      ],
      trimestre: [
        {
          required: true,
          message: 'Please select Activity zone',
          trigger: 'change',
        },
      ],
    })

    const filtredSubjects = computed(() => {
      if (filter.value) {
        return listSubject.value.filter((s: ISubject) => {
          return s.name.includes(filter.value.toString())
        })
      }
      return listSubject.value
    })

    onMounted(async () => {
      // get the student
      ApiService.setHeader()
      await ApiService.post('students/filter', {
        query: { _id: route.params.id },
        aggregation: [
          {
            $set: {
              classRoom: {
                $convert: {
                  input: '$schoolarYearsHistory.' + sy,
                  to: 'objectId',
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: 'classrooms',
              localField: 'classRoom',
              foreignField: '_id',
              as: 'classRoom',
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: { $arrayElemAt: ['$classRoom._id', 0] },
              classRoomName: {
                $arrayElemAt: ['$classRoom.name', 0],
              },
              teachers: {
                $arrayElemAt: ['$classRoom.teachers', 0],
              },
              BirthDate: 1,
              firstName: 1,
              lastName: 1,
              gender: 1,
              photo: 1,
              schoolarYearsHistory: 1,
            },
          },
        ],
      }).then(({ data }) => {
        if (data.length > 0) {
          student.value = data[0]
          classID.value = student.value.schoolarYearsHistory[sy as string]
        } else console.error(data)
      })

      //get subjects
      ApiService.setHeader()
      await ApiService.post('teacher/classRommDetails', {
        query: {
          teacher: store.getters.currentUser._id,
        },
      })
        .then(({ data }) => {
          listSubject.value = []
          const classroom = data.find((el) => el.classRoom._id == classID.value)
          if (classroom) {
            listSubject.value = classroom.subjects

            classroom.modulesSpecific.map((m) => {
              listSubject.value.push(...m.subjects)
            })
            classroom.modules.map((m) => {
              listSubject.value.push(...m.subjects)
            })
          }

          //remove duplicated
          listSubject.value = listSubject.value.filter(
            (subject, index) =>
              listSubject.value.findIndex((s) => s._id == subject._id) ===
                index && subject.status !== 'inactive'
          )
        })
        .catch((e) => console.log(e))

      ApiService.post('mark/filter', {
        query: {
          student: route.params.id,
          schoolarYear: sy,
          trimester: trimester || '1',
          classroom: classID.value,
        },
      }).then(({ data }) => {
        data.forEach((el: Mark) => {
          if (el.type == 'one') {
            marks.value.push(el)
          }
        })
        markRefs.value = []
        grades.value = data
        updatedGrades.value = true
        loading.value = false
      })

      ApiService.get(
        `/mark/maxmin/${classID.value}/${targetData.value.trimestre || '1'}`
      ).then(({ data }) => {
        maxmin.value = data
        loadedMaxmin.value = true
      })
    })

    const grades = ref([])
    const marks = ref<Mark[]>([])
    const updatedGrades = ref<boolean>(false)
    const loadedMaxmin = ref<boolean>(false)

    watch(
      targetData,
      async () => {
        listSubject.value = []
        updatedGrades.value = false
        loadedMaxmin.value = false
        loading.value = true

        const currentMonth = new Date().getMonth()

        disabledAccess.value = true

        if (targetData.value.trimestre == '1') {
          if (currentMonth >= 8 && currentMonth <= 11)
            disabledAccess.value = false
        } else if (targetData.value.trimestre == '2') {
          if (currentMonth >= 12 && currentMonth <= 2)
            disabledAccess.value = false
        } else if (targetData.value.trimestre == '3') {
          if (currentMonth >= 2 && currentMonth <= 5)
            disabledAccess.value = false
        }

        await ApiService.post('teacher/classRommDetails', {
          query: {
            teacher: store.getters.currentUser._id,
          },
        })
          .then(({ data }) => {
            listSubject.value = []
            const classroom = data.find(
              (el) => el.classRoom._id == classID.value
            )
            if (classroom) {
              listSubject.value = classroom.subjects

              classroom.modulesSpecific.map((m) => {
                listSubject.value.push(...m.subjects)
              })
              classroom.modules.map((m) => {
                listSubject.value.push(...m.subjects)
              })
            }

            //remove duplicated
            listSubject.value = listSubject.value.filter(
              (subject, index) =>
                listSubject.value.findIndex((s) => s._id == subject._id) ===
                  index && subject.status !== 'inactive'
            )
          })
          .catch((e) => console.log(e))

        grades.value = []
        maxmin.value = []

        ApiService.post('mark/filter', {
          query: {
            student: route.params.id,
            schoolarYear: sy,
            trimester: targetData.value.trimestre || '1',
            classroom: classID.value,
          },
        }).then(({ data }) => {
          marks.value = []
          data.forEach((el: Mark) => {
            if (el.type == 'one') {
              marks.value.push(el)
            }
          })
          markRefs.value = []
          grades.value = data
          updatedGrades.value = true
          loading.value = false
        })

        ApiService.get(
          `/mark/maxmin/${classID.value}/${targetData.value.trimestre || '1'}`
        ).then(({ data }) => {
          maxmin.value = data
          loadedMaxmin.value = true
        })
      },
      { deep: true }
    )

    const getMark = (subject: string) => {
      const mark = marks.value.find((m: Mark) => {
        return (
          m.subject == subject &&
          String(m.trimester) == String(targetData.value.trimestre)
        )
      })
      return mark || { mark: '' }
    }

    const markRefs = ref<
      Array<{
        newMarks: string
        edits: boolean
        marks: { _id: number; mark: string }
        addMark: (type: string, alert?: boolean) => void
        updateMark: (id: number, type: string, alert?: boolean) => void
      }>
    >([])

    const addRef = (el) => {
      markRefs.value.push(el)
    }

    const saveAll = () => {
      let thereEmpty = false
      let thereInvalid = false
      markRefs.value.forEach((el) => {
        if (el.edits && !el.newMarks) {
          thereEmpty = true
          return Swal.fire({
            text: t('notes.noEmpty'),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        }
        if (Number(el.newMarks) > 20 || Number(el.newMarks) < 0) {
          thereInvalid = true
          return Swal.fire({
            text: t('notes.noInvalid'),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        }
      })

      if (!thereEmpty && !thereInvalid) {
        markRefs.value.forEach((el) => {
          if (!el.marks.mark && el.newMarks) {
            el.addMark('one', false)
          }
          if (el.edits)
            if (el.newMarks) {
              el.updateMark(el.marks._id, 'one', false)
            }
        })

        Swal.fire({
          text: t('notes.savedAll'),
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok!',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

        event('Save all marks', {
          event_category: 'Marks',
          event_label: 'Student profile',
          value: 1,
        })
      }
    }

    return {
      t,
      targetData,
      rules,
      student,
      onMounted,
      listSubject,
      filter,
      filtredSubjects,
      apiUrl,
      grades,
      updatedGrades,
      getMark,
      classID,
      maxmin,
      loadedMaxmin,
      addRef,
      saveAll,
      loading,
      disabledAccess,
    }
  },
})
