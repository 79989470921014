
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { useI18n } from "vue-i18n";

interface MAXMIN {
  _id: string;
  max: number;
  min: number;
}

interface Mark {
  _id: string;
  type: string;
  mark: string;
  schoolarYear: string;
  student: string;
  subject: string;
  trimester: number;
}

interface Subject {
  _id: string;
  name: string;
}

export default defineComponent({
  name: "Student-Attendance-Statistic",
  props: {
    title: String,
    description: String,
    data: Object,
    height: Number,
    maxmin: Array,
    subjects: Array,
  },
  components: {},
  setup(props) {
    const { t } = useI18n();

    const subjects = props.subjects as Subject[];

    const subjectsSerie: string[] = [];
    const minSerie: number[] = [];
    const maxSerie: number[] = [];
    const studentSerie: number[] = [];

    const grades = props?.data as Mark[];
    const maxmin = props?.maxmin as MAXMIN[];

    console.log(subjects);
    for (const m of maxmin) {
      const grade = grades.find((g) => g.subject == m._id && g.mark != "");
      if (grade) {
        const sb = subjects.find((s) => s._id == m._id)?.name as string;
        if (sb) {
          subjectsSerie.push(sb);
          minSerie.push(m.min);
          maxSerie.push(m.max);
          studentSerie.push(Number(grade.mark));
        }
      }
    }

    const series = [
      {
        name: t("stats.maxMark"),
        data: maxSerie,
      },
      {
        name: t("stats.studentMark"),
        data: studentSerie,
      },
      {
        name: t("stats.minMark"),
        data: minSerie,
      },
    ];

    const labelColor = getCSSVariableValue("--bs-gray-800");
    const dangerColor = getCSSVariableValue("--bs-danger");
    const lightDanger = getCSSVariableValue("--bs-light-danger");
    const primaryColor = getCSSVariableValue("--bs-primary");
    const lightprimary = getCSSVariableValue("--bs-light-primary");
    const successColor = getCSSVariableValue("--bs-success");
    const lightsuccess = getCSSVariableValue("--bs-light-success");

    const colors = [successColor, primaryColor, dangerColor];
    const lightColors = [lightDanger, lightsuccess, lightprimary];

    const options = {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: false,
        },
      },

      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors,
      },
      xaxis: {
        type: "string",
        categories: subjectsSerie,
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: [
        {
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
      ],
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {},
      },
      fill: {
        type: "gradient",
        gradient: {
          stops: [0, 100],
        },
      },
      colors,
      markers: {
        colors,
        strokeColor: lightColors,
        strokeWidth: 3,
      },
    };
    return {
      options,
      series,
    };
  },
});
